body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: scroll; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.david {
  font-family: 'David Libre', serif;
}

.fill {
  text-align: justify;
}

.fill::after {
  content: "";
  display: inline-block;
  width: 100%;
}

/* ol { counter-reset: item } */
/* li{ display: block } */
/* li:before { content: counters(item, ".") " "; counter-increment: item } */
li {
  list-style-position: inside;
}

.fixed-size {
  font-size: 14px;
}

/* input[type=text] {
  border: none;
  border-bottom: 2px solid black;
} */

.input-field {
  width: 150px;
  font-size: 12px;
  padding: 4px;
}

@media (max-width: 768px) {
  .input-field {
    width: 50px;
    height: 14px;
    font-size: 8px;
    padding: 2px;
  }
}

.text-fields{
  z-index: 1;
}

.box1 {
  z-index: 3;
}

.box2 {
  z-index: 2;
}

.box3 {
  z-index: 1;
}
